import { getFontWeightFromStyle } from '@lam-agency/toolkit/utils';
import { ITypestyle } from 'lam-toolkit-figma-plugin';

const getTypestyleCss = (typestyles: ITypestyle[]) => {
  const MIN_TABLET_SIZE = 768;
  const MIN_DESKTOP_SIZE = 1024;

  const fallbackFonts =
    'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  let css = ``;

  typestyles?.forEach(({ breakpoints, font, name, properties }) => {
    const percentageStringToNumber = (percentageString: string) =>
      parseInt(percentageString.replace('%', ''));

    const letterSpacingPercentageMobile = percentageStringToNumber(
      breakpoints.sm.letterSpacing
    );
    const letterSpacingPercentageTablet = percentageStringToNumber(
      breakpoints.md.letterSpacing
    );
    const letterSpacingPercentageDesktop = percentageStringToNumber(
      breakpoints.lg.letterSpacing
    );

    const getLetterSpacingPxFromPercentage = (
      letterSpacingPercentage: number,
      fontSizePx: number
    ) => {
      return Math.round(fontSizePx * (letterSpacingPercentage / 100));
    };

    const letterSpacingPxMobile = getLetterSpacingPxFromPercentage(
      letterSpacingPercentageMobile,
      breakpoints.sm.fontSize
    );
    const letterSpacingPxTablet = getLetterSpacingPxFromPercentage(
      letterSpacingPercentageTablet,
      breakpoints.md.fontSize
    );
    const letterSpacingPxDesktop = getLetterSpacingPxFromPercentage(
      letterSpacingPercentageDesktop,
      breakpoints.lg.fontSize
    );

    const typekitFont = {
      current: 'Neue Haas Unica Pro',
      override: 'neue-haas-unica'
    };

    css += `
				.${name.toLowerCase()} {
					font-family: "${
            font.family === typekitFont.current
              ? typekitFont.override
              : font.family
          }", ${fallbackFonts};
          font-weight: ${getFontWeightFromStyle(font.style)};
          font-size: ${breakpoints.sm.fontSize}px;
					line-height: ${breakpoints.sm.lineHeight};
					letter-spacing: ${letterSpacingPxMobile}px;
					text-transform: ${properties.case === 'uppercase' ? 'uppercase' : 'none'};
					text-decoration: ${
            properties.textDecoration === 'underline' ? 'underline' : 'none'
          };
				}
				@media (min-width: ${MIN_TABLET_SIZE}px) {
					.${name.toLowerCase()} {
						font-size: ${breakpoints.md.fontSize}px;
					  line-height: ${breakpoints.md.lineHeight};
					  letter-spacing: ${letterSpacingPxTablet}px;
					}
				}
				@media (min-width: ${MIN_DESKTOP_SIZE}px) {
					.${name.toLowerCase()} {
						font-size: ${breakpoints.lg.fontSize}px;
					  line-height: ${breakpoints.lg.lineHeight};
					  letter-spacing: ${letterSpacingPxDesktop}px;
					}
				}
			`;
  });

  return css;
};

export default getTypestyleCss;
