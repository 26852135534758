import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ImageCopyDownloadButtons,
  IntersectionAnimation,
  SVG
} from '@lam-agency/toolkit/components';
import { ReactComponent as Logo } from '../../../../custom/components/Logo/logo.svg';
import { parseImageFromFigma } from '@lam-agency/toolkit/utils/figma';
import { useMVT } from '@lam-agency/toolkit/stores';
import { svgToDataURL, getCSSVariableValue } from '@lam-agency/toolkit/utils';

interface IProps {
  logos?: string[];
}

const LogoInspector = ({ logos }: IProps) => {
  if (!logos) {
    return (
      <div className={styles.container}>
        <LogoTile scheme="dark" />
        <LogoTile scheme="light" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {logos.map((logo, i) => (
        <LogoPreview key={i} logo={logo} />
      ))}
    </div>
  );
};

export default LogoInspector;

const LogoPreview = ({ logo }: { logo: string }) => {
  const { projectString } = useMVT();

  const logoData = parseImageFromFigma(projectString, logo);

  if (!logoData) return null;

  const logoSrc = logoData!.image;

  return (
    <>
      <LogoTile logoSrc={logoSrc} scheme="dark" />
      <LogoTile logoSrc={logoSrc} scheme="light" />
    </>
  );
};

const LogoTile = ({
  logoSrc,
  scheme
}: {
  logoSrc?: string;
  scheme: 'light' | 'dark';
}) => {
  const colorVariable = scheme === 'light' ? '--color-black' : '--color-white';
  const color = getCSSVariableValue(colorVariable);

  const logoDataUrl = logoSrc
    ? logoSrc
    : svgToDataURL(
        <Logo
          style={{
            color: color
          }}
        />
      );

  return (
    <IntersectionAnimation
      animation="fadeGrow"
      className={cn(styles.card, styles[scheme])}
    >
      <ImageCopyDownloadButtons
        colorScheme={scheme}
        logoDataUrl={logoDataUrl}
        className={styles.buttons}
      />

      {logoSrc ? (
        <img className={styles.image} src={logoSrc} alt="" />
      ) : (
        <SVG svg="logo" className={styles.image} />
      )}
    </IntersectionAnimation>
  );
};
