import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TypeEditor,
  ITypeEditorDefaultSettings
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { ISliceTypography } from 'lam-toolkit-figma-plugin';
import { parseButtonDataFromFigma } from '@lam-agency/toolkit/utils/figma';

const FigmaTypography = ({
  exampleBody,
  exampleDisplay,
  exampleHeading,
  heading,
  buttonText
}: ISliceTypography) => {
  const bodyDefault = {
    text: exampleBody,
    letterSpacing: 0,
    lineHeight: 122,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const headingDefault = {
    text: exampleHeading,
    letterSpacing: -0.03,
    lineHeight: 110,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const displayDefault = {
    text: exampleDisplay,
    // todo: check proper letterSpacing value in em
    letterSpacing: 0,
    lineHeight: 100,
    alignment: 'left'
  } as ITypeEditorDefaultSettings;

  const buttons = parseButtonDataFromFigma([buttonText]);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader
          className={styles.header}
          data={{
            heading,
            buttons
          }}
        />
      </LayoutProvider>

      <TypeEditor
        typestyle="Display"
        placeholder={exampleDisplay}
        defaultSettings={displayDefault}
      />

      <TypeEditor
        typestyle="Heading"
        placeholder={exampleHeading}
        defaultSettings={headingDefault}
      />

      <TypeEditor
        typestyle="Body"
        placeholder={bodyDefault.text}
        defaultSettings={bodyDefault}
        noBorder
      />
    </LayoutProvider>
  );
};

export default FigmaTypography;
