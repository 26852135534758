import * as React from 'react';
import AppProvider from './src/common/context/AppContext';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => (
  <div className="tk-neue-haas-unica">
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/dvj7hqc.css" />
    </Helmet>

    <AppProvider>{element}</AppProvider>
  </div>
);
