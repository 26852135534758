// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--48489";
export var card = "styles-module--card--2516b";
export var container = "styles-module--container--8361e";
export var dark = "styles-module--dark--d15e3";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--b1fea";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--d9ee0";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";